export default [{
  title: 'Spotify Artists',
  subTitle: 'Query Artists and Albums',
  desc: 'React, Spotify API, Tailwind.',
  website: 'https://zealous-euclid-adadad.netlify.app?ref=oscarhoang',
  repo: 'https://gitlab.com/oscar.hoang/simple-spotify-app?ref=oscarhoang',
  icon: 'FiHeadphones'
}, {
  title: 'Github Issues',
  subTitle: 'Query GitHub Repos',
  desc: 'React, GitHub API, Tailwind.',
  website: 'https://wonderful-northcutt-22f3cd.netlify.app?ref=oscarhoang',
  repo: 'https://gitlab.com/oscar.hoang/github_issues_explorer?ref=oscarhoang',
  icon: 'FiGithub'
}, {
  title: 'Auth0 Quotes List',
  subTitle: 'Full-stack CRUD operations',
  desc: 'React, Auth0, Express, MySQL, Tailwind.',
  website: 'https://auth0-oscar-quotes.herokuapp.com?ref=oscarhoang',
  repo: 'https://github.com/oscar-hustlebox/auth0-quotes?ref=oscarhoang',
  icon: 'FiLock'
}, {
  title: 'Personal Portfolio',
  subTitle: 'Showcase of my work',
  desc: 'React, TypeScript, i18Next (English/Thai), Tailwind.',
  website: 'https://www.oscarhoang.com?ref=oscarhoang',
  repo: '',
  icon: 'FiMonitor'
}, {
  title: 'Weather App',
  subTitle: 'View Daily Weather',
  desc: 'React, TypeScript, Hooks, Tailwind.',
  website: 'https://festive-franklin-c9af18.netlify.app?ref=oscarhoang',
  repo: 'https://gitlab.com/oscar.hoang/weather-app?ref=oscarhoang',
  icon: 'FiSun'
}, {
  title: 'Invoice App',
  subTitle: 'Frontend CRUD Operations',
  desc: 'React, TypeScript, Redux, Custom Middleware, Tailwind.',
  website: 'https://silly-mahavira-098502.netlify.app?ref=oscarhoang?',
  repo: 'https://gitlab.com/oscar.hoang/invoice-app?ref=oscarhoang',
  icon: 'FiDollarSign'
}, {
  title: 'Todo App',
  subTitle: 'Frontend CRUD operations',
  desc: 'React, TypeScript, Redux, react-hook-form, ChakraUI.',
  website: 'https://rainbow-biscotti-ea14d7.netlify.app?ref=oscarhoang?',
  repo: 'https://github.com/oscar-hustlebox/todo-app?ref=oscarhoang',
  icon: 'FiEdit'
}, {
  title: 'Kanban App (wip)',
  subTitle: 'Frontend CRUD operations',
  desc: 'React, TypeScript, Redux, react-hook-form, ChakraUI.',
  website: 'https://singular-centaur-e9535d.netlify.app/?ref=oscarhoang?',
  repo: 'https://github.com/oscar-hustlebox/todo-app/pull/1?ref=oscarhoang',
  icon: 'FiTrello'
}
];
