import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import th from './locales/th.json';

const resources = {
  en: {
    translation: en
  },
  th: {
    translation: th
  }
};

export const LANGUAGE_OPTIONS = [{
  key: 'english',
  text: 'English',
  value: 'en'
}, {
  key: 'thai',
  text: 'Thai',
  value: 'th'
}];

i18next
  .use(initReactI18next) // pass the i18next instance to react-i18next.
  .use(LanguageDetector)
  .init({
    resources,
    detection: {
      // order and from where user language should be detected.
      order: ['navigator', 'localStorage'], // browser lng will be used before localStorage
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'], // cache user language on
      excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    },
    fallbackLng: 'en', // if user computer language is not on the list of available languages
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false
    }
  });

export function strings(key, options = {}) {
  return i18next.t(key, options);
}

export function lookupExists(key, options = {}) {
  return i18next.exists(key, options);
}

export default i18next;
