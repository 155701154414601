import React from 'react';
import PropTypes from 'prop-types';
import { FiGlobe } from 'react-icons/fi';

import { LANGUAGE_OPTIONS } from '../i18n';

export const Dropdown = ({ currentLanguage, handleChange, strings }) => {
  return (
    <div className='flex items-center gap-2'>
      <FiGlobe />
      <select onChange={(e) => handleChange(e.target)} value={currentLanguage.value}>
        {LANGUAGE_OPTIONS.map((lang) => (
          <option
            key={lang.key}
            value={lang.value}
            className='flex items-center gap-2'
          >
            {lang.flag}&nbsp;&nbsp;
            {strings(`languages.${lang.key}`)}
          </option>
        ))}
      </select>
    </div>
  );
};

Dropdown.propTypes = {
  currentLanguage: PropTypes.object,
  handleChange: PropTypes.func,
  strings: PropTypes.func,
};
