import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import { useI18Next } from './components/context/i18NextContext';
import { useLanguage } from './components/hooks/useLanguage';

import { Card } from './components/Card';
import { Dropdown } from './components/Dropdown';

import projects from './data/projects';
import code from './data/code';

const App = () => {
  const [currentLanguage, handleChange] = useLanguage();
  const { strings } = useI18Next();

  return (
    <div className='bg-gray-50'>
      <div className='container mx-auto'>
        <div className='flex flex-col justify-between bg-white shadow-sm'>
          <div style={{ maxWidth: '1024px' }} className='mx-auto px-8'>
            <div>
              <div>
                <header className='flex items-center justify-between py-8 border-b'>
                  <div>
                    <p className='text-2xl font-extrabold leading-10'>OscarHoang.com</p>
                  </div>
                  <div></div>
                </header>
                <section className='my-8'>
                  {/* hero */}
                  <div className='flex flex-col-reverse items-center md:flex-row space-x-0 md:space-x-20'>
                    <div className='w-full md:w-1/2'>
                      <div className='mb-4'>
                        <p className='text-2xl font-extrabold leading-10'>{strings('hello')}.</p>
                        <p className='text-5xl font-extrabold'>{strings('myNameIs')}</p>
                      </div>
                      <div className='mb-8'>
                        <p className='text-xl'>{strings('frontEnd')}</p>
                      </div>
                      <div className='mb-16'>
                        <p className='text-lg'>
                          {strings('intro1')}
                        </p>
                        <br/>
                        <p className='text-lg'>
                          {strings('intro2')}
                        </p>
                      </div>
                      <div className='mb-8'>
                        <a
                          href='mailto:oscar@hustlebox.ca'
                          target='_blank'
                          rel='noreferrer'
                          className='
                          bg-purple-500 hover:bg-purple-700
                            py-4 px-10 text-white font-semibold
                            rounded shadow-md focus:outline-none
                            transition duration-500 ease-in-out
                          '
                        >
                          {strings('emailMe')}
                        </a>
                      </div>
                      <div className='flex flex-col md:flex-row items-start md:items-center gap-4'>
                        <div className='flex gap-2 items-center'>
                          <p className='text-2xl text-green-400'><AiOutlineCheckCircle/></p>
                          <p className='text-base'>Modern Frameworks</p>
                        </div>
                        <div className='flex gap-2 items-center'>
                          <p className='text-2xl text-green-400'><AiOutlineCheckCircle/></p>
                          <p className='text-base'>React</p>
                        </div>
                        <div className='flex gap-2 items-center'>
                          <p className='text-2xl text-green-400'><AiOutlineCheckCircle/></p>
                          <p className='text-base'>Testing Suites</p>
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-center items-center w-full md:w-1/2 p-8 mb-8 md:mb-0'>
                      <img src='oscar-yellow.jpg' />
                    </div>
                  </div>
                </section>
              </div>

              <main>
                <section className='py-28'>
                  <div className='mb-8'>
                    <p className='text-4xl font-extrabold'>{strings('latestProjects')}</p>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
                    {projects?.map(({ title, subTitle, desc, website, repo, icon }) => (
                      <Card
                        key={title}
                        title={title}
                        subTitle={subTitle}
                        desc={desc}
                        website={website}
                        repo={repo}
                        icon={icon}
                      />
                    ))}
                  </div>
                </section>

                <section className='py-28'>
                  <div className='flex flex-col md:flex-row space-y-10 md:space-y-0 space-x-0 md:space-x-20'>
                    <div className='w-full md:w-1/2'>
                      <div className='mb-4'>
                        <p className='text-4xl font-extrabold'>{strings('introduction')}</p>
                      </div>
                      <p className='text-lg'>
                        {strings('introductionContent')}
                      </p>
                    </div>
                    <div className='w-full md:w-1/2'>
                      <div className='mb-4'>
                        <p className='text-4xl font-extrabold'>{strings('technicality')}</p>
                      </div>
                      <p className='text-lg'>
                        {strings('technicalityContent')}
                      </p>
                    </div>
                  </div>
                </section>

                <section className='my-28'>
                  <div className='mb-8'>
                    <p className='text-4xl font-extrabold'>{strings('codeSamples')}</p>
                  </div>
                  <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    {code?.map(({ title, subTitle, desc, repo, website, icon }) => (
                      <Card
                        key={title}
                        title={title}
                        subTitle={subTitle}
                        desc={desc}
                        repo={repo}
                        website={website}
                        icon={icon}
                      />
                    ))}
                  </div>
                </section>
                <section className='my-28'>
                {/* call to action */}
                <div className='grid grid-cols-6 gap-4'>
                  <div className='col-start-1 col-span-6 md:col-start-2 md:col-span-4'>
                    <div className='flex flex-col gap-4 items-center'>
                      <p className='text-4xl font-extrabold'>{strings('letsTalk')}</p>
                      <p className='text-lg text-center'>
                        {strings('askMeAnything')}
                      </p>

                      <a
                        href='mailto:oscar@hustlebox.ca'
                        target='_blank'
                        rel='noreferrer'
                        className='
                        bg-purple-500 hover:bg-purple-700
                        py-4 px-10 text-white font-semibold
                          rounded shadow-md focus:outline-none
                          transition duration-500 ease-in-out
                        '
                      >
                        {strings('emailMe')}
                      </a>
                      <p className='text-lg text-green-400'>{strings('willReplyBack')}</p>
                    </div>
                  </div>
                </div>
              </section>
              </main>
            </div>

            <footer className='flex items-center justify-between py-8 border-t'>
              <div className=''>
                <p>@2022 Oscar Hoang | oscar@hustlebox.ca</p>
              </div>
              <Dropdown
                currentLanguage={currentLanguage}
                handleChange={handleChange}
                strings={strings}
              />
              {/* {LANGUAGE_OPTIONS.map((lang) => (
                <div
                  key={lang.key}
                  className='flex fdc'
                  onClick={() => handleChange(lang)}
                  style={{ height: '48px' }}
                >
                  <div className='flex fdr mt1 mb1'>
                    <div>{strings(`shared.languages.${lang.key}`)}</div>
                    {lang.value === currentLanguage.value && (
                      <div>Current</div>
                    )}
                  </div>
                </div>
              ))} */}
            </footer>
          </div>

        </div>
      </div>
    </div>
  );
};

export default App;
