import React from 'react';
import PropTypes from 'prop-types';
import {
  FiHeadphones,
  FiGithub,
  FiLock,
  FiCalendar,
  FiBook,
  FiMapPin,
  FiMonitor,
  FiExternalLink,
  FiSun,
  FiDollarSign,
  FiEdit,
  FiTrello,
} from 'react-icons/fi';
import { useI18Next } from './context/i18NextContext';

interface Props {
  title: string,
  subTitle: string,
  desc: string,
  website: string,
  repo: string,
  icon: string
}

export const Card: React.FC<Props> = ({ title, subTitle, desc, website, repo, icon }) => {
  const { strings } = useI18Next();
  const renderIcon = (i:string) => {
    switch (i) {
      case 'FiHeadphones':
        return <FiHeadphones />;
      case 'FiGithub':
        return <FiGithub />;
      case 'FiLock':
        return <FiLock />;
      case 'FiCalendar':
        return <FiCalendar />;
      case 'FiBook':
        return <FiBook />;
      case 'FiMapPin':
        return <FiMapPin />;
      case 'FiMonitor':
        return <FiMonitor />;
      case 'FiSun':
        return <FiSun />;
      case 'FiDollarSign':
        return <FiDollarSign />;
      case 'FiEdit':
        return <FiEdit />;
      case 'FiTrello':
        return <FiTrello />;
      default:
        return null;
    }
  };

  return (
    <div
      className='
        max-h-screen bg-white shadow-lg rounded-lg h-96 border-2 p-4
        border-gray-500 flex items-center justify-between flex-col
      '>
      <div className='flex flex-col items-center'>
        <div
          style={{ width: '74px', height: '74px' }}
          className='flex items-center justify-center bg-white rounded-full border-2 border-black p-2'
        >
          <h3 className='text-3xl'>{renderIcon(icon)}</h3>
        </div>

        <div className='mt-8 flex items-center flex-col justify-center'>
          <h1 className='font-bold text-2xl'>{title}</h1>
          <p className='font-semibold text-xl text-gray-500'>{subTitle}</p>
          <div className='mb-4'>
            <p className='text-center mt-4'>{desc}</p>
          </div>
        </div>
      </div>

      <div className='flex w-full gap-2'>
        {website && (
          <a
            href={website}
            target='_blank'
            rel='noreferrer'
            className='
              flex items-center gap-2 flex-grow justify-center
              text-purple-500 border-2 border-purple-500
              hover:text-white hover:bg-purple-500
              rounded-lg py-2 px-2 transition duration-500 ease-in-out
            '
          >
            {strings('goToWebsite')}
            <FiExternalLink />
          </a>
        )}
        {repo && <a
          href={repo}
          target='_blank'
          rel='noreferrer'
          className='
            flex items-center gap-2 flex-grow justify-center
            text-purple-500 border-2 border-purple-500
            hover:text-white hover:bg-purple-500
            rounded-lg py-2 px-2 transition duration-500 ease-in-out
          '
          >
            {strings('goToFiles')}
            <FiExternalLink />
          </a>
        }
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  desc: PropTypes.any,
  website: PropTypes.any,
  repo: PropTypes.any,
  icon: PropTypes.any
};
