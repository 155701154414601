export default [{
  title: 'Attndnce',
  subTitle: 'web app',
  desc: 'Event management and online ticketing service for Dancers.',
  website: 'https://www.attndnce.com/?ref=oscarhoang',
  repo: '',
  icon: 'FiCalendar'
}, {
  title: 'Classcast',
  subTitle: 'web app',
  desc: 'Search around the world for live online classes and events.',
  website: 'https://keen-golick-ec11e1.netlify.app/?ref=oscarhoang',
  repo: '',
  icon: 'FiBook'
}, {
  title: 'Backing Locals',
  subTitle: 'web app',
  desc: 'Helping small businesses in Canada during the pandemic.',
  website: 'https://www.backinglocals.com/?ref=oscarhoang',
  repo: '',
  icon: 'FiMapPin'
}, {
  title: 'Hustlebox',
  subTitle: 'website',
  desc: 'Web development web services for small businesses.',
  website: 'https://www.hustlebox.ca/?ref=oscarhoang',
  repo: '',
  icon: 'FiMonitor'
}];
