import { useState } from 'react';
import i18next, { LANGUAGE_OPTIONS } from '../../i18n';

export const useLanguage = () => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const browserLanguage = i18next.language.split('-')[0] || 'en';
    const [language] = LANGUAGE_OPTIONS.filter((lang) => lang.value === browserLanguage) || null;
    return language || LANGUAGE_OPTIONS[0]; // set default language to En
  });

  const handleChange = ({ value }, e) => {
    const selectedLanguage = (e && e.value) || value;
    i18next.changeLanguage(selectedLanguage, () => { // (error, t)
      const [language] = LANGUAGE_OPTIONS.filter((lang) => lang.value === selectedLanguage);
      setCurrentLanguage(language);
    });
  };

  return [currentLanguage, handleChange];
};
